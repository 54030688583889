#user-guide .ek-snippet div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

#user-guide .ek-snippet .ek-text-snippet div p {
  width: 100%;
}

#user-guide .ek-snippet div img {
  height: 6rem;
  max-width: unset;
  min-width: unset;
  width: auto;
}

#user-guide .ek-section-2845 .ek-snippet .ek-text-snippet,
#user-guide .ek-section-2846 .ek-snippet .ek-text-snippet {
  align-items: flex-start;
}
