.bg-ocean {
  background-image: url(/images/aod-graphic.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
}

.bg-white {
  background-color: #fff;
}

.radius {
  border-radius: 8px;
}

.color-white {
  color: #fff;
}

.color-blue {
  color: #0383ba;
}

.color-purple {
  color: #978ef5;
}

.color-brown {
  color: #a85b54;
}

.left-50 {
  left: 50%;
}

.p-6 {
  padding: 1.5rem;
}

.max-h-16 {
  max-height: 4rem;
}

.w-full {
  width: 100%;
}

.min-h-body {
  min-height: calc(100vh - 10rem);
}

.container-sm {
  margin: 0 auto;
  max-width: 62rem;
  width: 90%;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.p-0 {
  padding: 0;
}

.p-8 {
  padding: 2rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-20 {
  padding-top: 5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-8 {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.py-12 {
  padding-bottom: 3rem;
  padding-top: 3rem;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-full {
  top: 100%;
}

.-translate-50 {
  transform: translate(-50%, -50%);
}

.shadow {
  box-shadow: 0px 0px 13px #231f2026;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.m-4 {
  margin: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mr-4 {
  margin-right: 1rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.font-header {
  font-family: ApercuLight, 'sans-serif';
}

.weight-300 {
  font-weight: 300;
}

@media (min-width: 768px) {
  .md\:w-third {
    width: 33.333%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:min-h-44 {
    min-height: 11rem;
  }
}
