.MuiAvatar-root {
  width: 15rem !important;
}

.MuiAvatar-img {
  object-fit: contain !important;
}
.MuiList-padding {
  padding: 0 !important;
}
