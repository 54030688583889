.hide-popup {
  display: none !important;
}

.popup {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 0, 0.5);
}

.popup___close {
  background-color: #ee020a;
  border: 1px solid #ee020a !important;
  cursor: pointer;
  color: #fff;
  font-weight: 700;
  padding: 0.25rem 0.5rem;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.popup___close:hover {
  background-color: #ee020a !important;
  box-shadow: 0 0 1px 1px #ee020a;
  filter: brightness(102%);
  transition: 0.25s ease-in-out;
}

.popup___inner {
  position: absolute;
  top: 50%;
  left: 50%;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  background-color: #fff;
  border-radius: 5px;
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .popup___inner {
    min-width: 70%;
  }
}
