body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ek-form-top-notification,
.ek-quesiton-mandatory-indicator,
.ek-describe.ek-isNotValid {
  display: none;
}

.show-validation .ek-describe.ek-isNotValid {
  display: inline;
  color: red;
}

#add-response-button {
  background: #373c5b;
  color: #fff;
  font-size: 14px;
  margin: 0.5em;
  padding: 0.8em 1em;
  border: 2px solid #373c5b;
  border-radius: 4rem;
  min-width: 10rem;
  font-weight: unset;
  cursor: pointer;
}

#add-response-button-text {
  margin: 0 auto;
}

#toolbox-rescource .ek-snippet {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  box-shadow: 0px 0px 13px #231f2026;
  border: none;
  padding: 0 0 1rem 0;
  margin: 0 1rem 1rem 0;
  background-color: #e8e8ed;
}
#toolbox-rescource .ek-section-container {
  background-color: transparent;
}

#toolbox-rescource div.media-wrap.image-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 10rem;
  margin-top: 0;
  border-radius: 0.8rem 0.8rem 0 0;
}

#toolbox-rescource .ek-snippet img {
  box-sizing: border-box;
  margin: 2rem;
  width: 100%;
}
#toolbox-rescource .ek-snippet,
#toolbox-rescource .ek-snippet > .ek-text-snippet {
  display: block !important;
  border-radius: 0.8rem;
  min-height: 10rem !important;
}
#toolbox-rescource .ek-btn-container {
  margin: 2rem 0;
}
#toolbox-rescource .ek-btn-link {
  padding: 0.7rem 1.2rem;
  border-radius: 10rem;
  font-weight: bold;
  text-align: center;
  align-items: center;
  border: 0.2rem solid #ee020a;
  margin: 3rem 0 0 0;
  color: #ee020a;
}
#toolbox-rescource .ek-text-snippet {
  text-align: center;
}

#toolbox-rescource h4 {
  font-size: 1.4rem;
  color: #373c5b;
  text-transform: uppercase;
  margin: 1rem 0;
}
#toolbox-rescource li::before {
  margin-right: 5px;
  content: none;
  color: #373c5b;
}
#toolbox-rescource li {
  color: #373c5b;
  margin-bottom: 0.3rem;
}
#toolbox-rescource li:first-letter {
  text-transform: capitalize;
}

#toolbox-rescource,
.ek-event-reveal,
.ek-section > div {
  background-color: transparent !important;
}

#toolbox-rescource .animated .ek-event-reveal {
  border: none;
}
#toolbox-rescource .ek-snippet,
#toolbox-rescource .ek-snippet > div {
  min-height: 37rem;
}

ul,
ol,
li {
  list-style: none;
}
