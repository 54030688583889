a {
  text-decoration: none;
}

@font-face {
  font-family: Apercu;
  src: url('https://ekardo-apps.s3-ap-southeast-2.amazonaws.com/values/apercu-medium.otf')
    format('opentype');
}

@font-face {
  font-family: ApercuLight;
  src: url('https://ekardo-apps.s3-ap-southeast-2.amazonaws.com/situation-forms/apercu-light.otf')
    format('opentype');
}

html {
  scroll-behavior: smooth;

  --pink: #e84d7b;
  --blue: #1b97c2;
  --purple: #978ef5;
  --burgandy: #a85b54;
}

/* Helpers */

.h-color-pink {
  color: #e84d7b;
}

.h-color-blue {
  color: #1b97c2;
}

.h-color-purple {
  color: #978ef5;
}

.h-color-burgandy {
  color: #a85b54;
}

body {
  font-family: ApercuLight, 'sans-serif';
  font-size: 17px;
}

.ek-container h1 {
  color: inherit;
  background-color: transparent;
  padding: 12px;
  border-radius: 3px;
  font-size: 1.6em;
  /* text-transform: uppercase; */
  letter-spacing: 1px;
  margin-top: 0.25rem;
}

.ek-quote-icon {
  align-items: center;
  background-color: #00c7dd;
  border-radius: 50%;
  bottom: 0 !important;
  display: flex;
  height: 0.6em;
  justify-content: center;
  left: 3%;
  transform: translateY(50%);
  width: 0.6em;
  z-index: 9;
}

.ek-text-snippet h2 {
  color: #373c5b;
  display: inline-block;
  font-size: 1.4em;
  font-weight: 600;
  margin: 0.8em 0;
  position: relative;
  text-transform: uppercase;
  width: auto;
}

.ek-text-snippet h2::after {
  position: absolute;
  content: '';
  bottom: -10px;
  left: 0;
  height: 5px;
  width: 100%;
  background-color: #0c9ab9;
}

.ek-quote-container .ek-quote-icon:last-of-type {
  bottom: 0 !important;
  left: 90%;
  transform: translateY(-50%);
}

.ek-quote-body {
  background-color: #fff;
  border-radius: 15px;
  color: #000;
  font-style: initial !important;
  margin: 0 0.4rem !important;
  padding: 2.5em 2em;
}

.ek-event-reveal .ek-event-reveal-title div {
  font-family: ApercuLight, 'sans-serif';
}

.ek-snippet .slick-slider .slick-prev,
.ek-snippet .slick-slider .slick-next {
  height: auto;
  width: auto;
}

.slick-prev:before,
.slick-next:before {
  color: #373c5b !important;
  font-size: 36px !important;
  opacity: 1 !important;
}

.c-modal {
  z-index: 999;
}

.c-print * {
  display: none;
}

.ek-popup {
  padding: 1em;
}

.ek-popup__button {
  font-family: inherit;
}

.ek__audio--accessible {
  background-color: #373c5b !important;
}

.ek__audio--accessible-title {
  margin: 0 0 1rem 0;
}

.rhap_main-controls-button,
.rhap_time,
.rhap_repeat-button,
.rhap_volume-button {
  color: #fff !important;
}

.rhap_progress-indicator,
.rhap_progress-filled,
.rhap_volume-indicator {
  background: #0c9ab9 !important;
}

.rhap_repeat-button {
  display: none;
}

.ek-audio {
  background-color: #373c5b;
  color: #fff;
  border-radius: 8px;
  margin: 1em 0;
  padding: 1em;
}

.ek-audio__controls {
  margin: 0 !important;
}

.ek-audio__outer {
  background-color: #00c7dd !important;
  height: 0.8em !important;
  margin-top: 0 !important;
  width: 0.8em !important;
}

.ek-audio__file p {
  animation: none !important;
  padding: 0 0 0 1em !important;
}

.ek-content-wrapper .ek-section-3056 {
  width: calc(100% - 2rem);
  margin: 0 auto;
}

.ui-datepicker .ui-datepicker-header {
  display: flex;
  justify-content: space-around;
}

.ui-datepicker-header a {
  cursor: pointer;
}

@media only screen and (min-width: 500px) {
  .ek-section-3056 .ek-section-container {
    min-height: 20rem;
  }

  .ek-section-3056 .ek-section-container > div {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 500px) {
  /* FMQ form responsive layout */
  .ek-snippet-8567 .ek-question {
    flex-direction: column;
  }

  .ek-snippet-8567 .ek-question .ek-question__item,
  .ek-snippet-8567 .ek-question .ek-question__response {
    width: 100%;
  }
}

* {
  -webkit-print-color-adjust: exact;
}
@media print {
  .ek-app-container,
  .slick-slider {
    display: none !important;
  }
}

******************
  Toolkit
  Resources
  *************************
  #toolbox-rescource
  .ek-snippet,
#toolbox-rescource .ek-snippet > div {
  display: flex;
  justify-content: center;
}

#toolbox-rescource .button-snippet-text {
  margin: 0.6rem;
  font-size: 0.8rem;
}

#toolbox-rescource .small-button {
  border-radius: 0;
}

#toolbox-rescource .ek-text-snippet h4 strong {
  font-family: Apercu, 'sans-serif';
  font-size: 1.4rem;
}

#toolbox-rescource .animated .ek-event-reveal {
  border-radius: 0;
  padding: 6px;
  margin-bottom: 1rem;
  margin: 0 -1px 1rem -1px;
  background-color: #fff;
}

#toolbox-rescource .ek-snippet img {
  width: auto;
  min-width: 0px;
  max-height: 5rem;
  max-width: 10rem;
}

#toolbox-rescource .ek-snippet-10092 img,
#toolbox-rescource .ek-snippet-10111 img,
#toolbox-rescource .ek-snippet-10131 img,
#toolbox-rescource .ek-snippet-10149 img,
#toolbox-rescource .ek-snippet-10157 img {
  width: 5rem;
}

#resource-general .bookmark-icon,
#resource-general .go-to-page,
#resource-general .go-to-page svg {
  color: #fff !important;
}

.ek-event-reveal {
  padding: 1rem;
}

.ek-event-reveal-title {
  color: #fff !important;
}

.ek-snippet-13729 .ek-question__response {
  width: 50%;
}

@media (min-width: 63em) {
  .ek-popup__icon {
    height: 7rem !important;
    width: 7rem !important;
  }
}

.ek-section-6132 .ek-question__item {
  display: none;
}

.ek-form-item .ek-question {
  align-items: baseline;
}

.ek-container-8249 ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

.ek-container-8249 li {
  align-items: center;
  background-color: #eeeef7;
  border-radius: 14px;
  color: #373c5b;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-weight: 600;
  margin: 0 0.5rem 0.5rem 0.5rem;
  text-transform: uppercase;
  padding: 2rem;
  text-align: center;
}

.ek-container-8249 li::before {
  display: none;
}

.ek-question__response {
  width: 50% !important;
}

.MuiSlider-root {
  color: #0c9ab9 !important;
}

#save-response-button img,
#discard-response-button img,
#delete-user-generated-response-button img {
  display: none;
}

#save-response-button {
  background-image: url('/images/tick-check.svg');
  background-position: center;
  background-size: contain;
  height: 24px;
  margin-left: 0.6rem;
  width: 24px;
}

#discard-response-button,
#delete-user-generated-response-button {
  background-image: url('/images/bin.svg');
  background-position: center;
  background-size: contain;
  height: 24px;
  margin-left: 0.6rem;
  width: 24px;
}

.ek-section {
  background-color: rgba(255, 255, 255, 0.5);
  margin-bottom: 1rem;
  padding: 1rem;
}

.ek-content-wrapper .ek-section-container {
  margin: 0;
}

/* Global acitivty component styles */

.c-modal-card {
  max-height: 75vh;
}

@media (max-width: 1100px) {
  .c-modal-card {
    top: 13vh;
  }
}

/* Kanzi overrides */
.kanzi-audio-player#kanzi_audio_player {
  flex-direction: column;
  left: 97.5% !important;
  top: 50% !important;
}

.kanzi-audio-controls {
  flex-direction: column;
}

.kanzi-audio-player .kanzi-speaker {
  border-radius: 0.15em 0.15em 0 0;
  height: 1rem;
}

.kanzi-brand {
  bottom: -40px !important;
}

.kanzi-audio-volume {
  right: 0;
  width: 8rem;
}

/* .c-modal-overlay {
  position: fixed;
  z-index: 99999;
} */

.ek-section-9183 .ek-question__item {
  display: none;
}

/* Ekardo class themes */

.ek-hide-on-desktop {
  display: none;
}

.ek-hide-on-mobile {
  display: none;
}

.ek-form-item {
  background-color: transparent !important;
}

.MuiSlider-thumb {
  margin-top: 0 !important;
  overflow: hidden !important;
  top: 50% !important;
  transform: translateY(-50%);
}

.ek-container h1 {
  margin: 0;
}

.ek-section,
.ek-snippet {
  padding: 0.5rem !important;
}

.ek-image-snippet img {
  border-radius: 5px;
}

/* Cms class styles */
.ek-global-accent-dark-blue {
  background: #233d58;
  color: #ffffff;
}
.ek-theme-breakoutbox-mustard {
  background: #e0e170;
  border-color: #f8f8fa;
}
.ek-theme-breakoutbox-blue {
  background: #0070aa;
  color: #ffffff;
  border-color: #0c9ab9;
  border-width: 1px;
}
.ek-theme-breakoutbox-light-blue {
  background: #0070aa;
  color: #ffffff;
  border-color: #0c9ab9;
  border-width: 1px;
}
.ek-theme-breakoutbox-light-red {
  background: #ec5c66;
  color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #f8f8fa;
}
.ek-global-accent-soft-red {
  background: #ec5c66;
  color: #ffffff;
}
.ek-domestic-accent-mid-purple {
  background: #cbb6d8;
}
.ek-domestic-accent-purple {
  background: #706aa8;
}
.ek-domestic-accent-dark-purple {
  background: #332582;
  color: #ffffff;
}
.ek-theme-breakoutbox-red {
  background: #70c9d7;
}
.ek-aod-accent-orange {
  background-color: #ffc060 !important;
  border-color: #f8f8fa;
}
.ek-domestic-accent-mustard {
  background-color: #e0e170 !important;
  border-color: #f8f8fa;
}
.ek-global-accent-purple {
  background: #834c8f;
  color: #ffffff;
}
.ek-domestic-accent-soft-pink {
  background: #f1b4d2;
}
.ek-rural-accent-burnt-orange {
  background: #93171e;
  color: #ffffff;
}
.ek-rural-accent-light-blue {
  background: #70c9d7;
}
.ek-global-accent-mid-blue {
  background: #0070aa;
  color: #ffffff;
}
.ek-rural-accent-earth {
  background: #a85b54;
}
.ek-domestic-accent-hot-pink {
  background: #b5398d;
  color: #ffffff;
}
.ek-domestic-accent-light-purple {
  background: #ece2f2;
}
.ek-rural-accent-soft-brown {
  background: #d8d3a7;
}
.ek-rural-accent-light-brown {
  background: #cb9812 !important;
}
.ek-rural-accent-brown {
  background: #8a3421;
  color: #ffffff;
}
.ek-rural-accent-earth-green {
  background: #355454;
}

.ek-global-accent-salmon {
  background: #ffedd2;
}

.ek-global-accent-orange {
  background: #ffc060;
}

.ek-global-accent-grey {
  background: #eff1f9;
}

.ek-global-accent-bright-blue {
  background: #1eb6cf;
}

.ek-aod-accent-ocean-blue {
  background-color: #0c9ab9;
}

.ek-aod-accent-ocean-blue *,
.ek-global-accent-purple * {
  color: #fff;
}
/* 
.jss2 {
  display: none !important;
} */

.jss6 {
  top: unset !important;
}

.ek-form-item .ek-question {
  align-items: flex-start;
}

.MuiSlider-markActive {
  background-color: transparent !important;
}

.ek-snippet-14195 .ek-question {
  flex-direction: column;
}

.ek-snippet-14195 .ek-question__item {
  margin-left: 0;
  margin-right: 3rem;
  width: 100%;
}

.ek-snippet-14195 .ek-question__response {
  width: 80% !important;
}

.ek-snippet-14195 .MuiSlider-markLabel {
  white-space: normal;
  display: inline-block;
  margin-top: 0.5rem;
  text-align: center;
  width: 8rem;
}

@media (min-width: 72em) {
  .ek-snippet-14195 .ek-question {
    flex-direction: row;
  }

  .ek-snippet-14195 .ek-question__item {
    margin-right: 5rem;
    width: 30%;
  }

  .ek-snippet-14195 .ek-question__response {
    width: 50% !important;
  }

  .ek-snippet-14195 .ek-form-item {
    margin-bottom: 1rem;
  }
}

/* Services images */
.ek-section-9165 img,
.ek-section-9167 img,
.ek-section-6283 img,
.ek-section-6287 img,
.ek-section-6289 img,
.ek-section-6290 img,
.ek-section-6292 img,
.ek-section-6294 img,
.ek-section-6296 img {
  max-width: 200px;
}
