.base-timer {
  position: relative;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  margin-right: 3rem;
}

/* Removes SVG styling that would hide the time label */
.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__label {
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  line-height: 3rem;
  font-size: 1rem;
}

/* The SVG path that displays the timer's progress */
.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}
